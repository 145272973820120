// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const useStyles = makeStyles(theme => ({
  subscribe: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: 'white',
    padding: '50px 10px',
    textAlign: 'center'
  },
  input: {
    backgroundColor: 'white'
  }
}))

export default function Subscribe() {
  const classes = useStyles()
  const [email, setEmail] = React.useState()
  const [message, setMessage] = React.useState()
  const [canSubmit, setCanSubmit] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const validate = () => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

  React.useEffect(() => {
    if (validate()) {
      setCanSubmit(true)
    }
  }, [email])

  const submitForm = () => {
    if (canSubmit) {
      addToMailchimp(email).then(res => {
        if (res.result === 'success') {
          setMessage("Thank you! You've been subscribed!")
          setSubmitted(true)
        } else {
          setMessage(res.msg)
        }
      })
    }
  }

  return (
    <div className={classes.subscribe}>
      <Container maxWidth="sm">
        <Typography variant="h5">How to Reach Us:</Typography>
        <Typography variant="body1" component="p" className={classes.text}>
          If you are interested in partnering or just want to support our
          efforts, we would love to hear from you, please sign up here:
        </Typography>
        {!submitted && (
          <div>
            <TextField
              style={{ maxWidth: '350px' }}
              id="outlined-dense"
              label="Email"
              name="email"
              type="email"
              margin="dense"
              variant="filled"
              onChange={e => setEmail(e.target.value)}
              fullWidth
              required
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
            />
            <br />
            <p>
              <Button
                variant="contained"
                color="secondary"
                disabled={!canSubmit}
                onClick={submitForm}>
                Submit
              </Button>
            </p>
          </div>
        )}
        <p dangerouslySetInnerHTML={{ __html: message }} />
      </Container>
    </div>
  )
}
