// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles({
  text: {
    marginBottom: 10
  }
})

export default function About() {
  const classes = useStyles()
  const file = useStaticQuery(graphql`
    query {
      coder: file(relativePath: { eq: "coding.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div style={{ padding: '40px 10px' }}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item md={9} sm={12} spacing={2}>
            <Typography variant="h6">Our Mission:</Typography>
            <Typography variant="body1" component="p" className={classes.text}>
              To provide underserved communities with a free education in
              computer science, that leads directly to employment opportunities
              in technology.
            </Typography>
            <Typography variant="h6">Who We Are:</Typography>
            <Typography variant="body1" component="p" className={classes.text}>
              A group of like-minded individuals with a passion for service and
              community. We are mostly veterans and some still on Active Duty-
              so rest assured, mission accomplishment is in our DNA.
            </Typography>
            <Typography variant="body1" component="p" className={classes.text}>
              2019 is the breakout year. Our goal is Your success!
            </Typography>
            <Typography variant="body1" component="p" className={classes.text}>
              Currently we are partnered with the YMCA through the{' '}
              <a href="http://www.lbymcayi.org">Youth Institute</a> in Long
              Beach, CA, but are expecting expansion across the nation.
            </Typography>
            <Typography variant="body1" component="p" className={classes.text}>
              2019 is the breakout year. Our goal is Your success!
            </Typography>
          </Grid>
          <Grid item md={3} sm={12} xs={12} spacing={2}>
            {' '}
            <Img fluid={file.coder.childImageSharp.fluid} />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
