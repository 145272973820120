// @flow
import React from 'react'
import { styled, makeStyles } from '@material-ui/core/styles'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import { KeyboardArrowDown } from '@material-ui/icons'

import Layout from '../components/layout'
import SEO from '../components/seo'
import About from '../components/About'
import Subscribe from '../components/Subscribe'

const MainImage = styled(BackgroundImage)(({ theme }) => ({
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100vh',
  color: 'white',
  textAlign: 'center',
  [theme.breakpoints.down('xs')]: {
    height: '94vh'
  }
}))

const MonoName = styled(Typography)(({ theme }) => ({
  fontFamily: 'monospace',
  paddingTop: '40vh',
  [theme.breakpoints.down('xs')]: {
    paddingTop: '30vh',
    fontSize: 40
  }
}))

const ArrowButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  position: 'absolute',
  bottom: 0,
  left: '50%',
  marginLeft: '-25.5px'
}))

const IndexPage = ({ data }) => {
  const classroom = data.classroom.childImageSharp.fluid

  const aboutRef = React.useRef(null)
  const subscribeRef = React.useRef(null)
  const handleScrollClick = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  const handleSubscribeClick = () => {
    if (subscribeRef.current) {
      subscribeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  return (
    <Layout>
      <SEO title="Home" />
      <MainImage fluid={classroom}>
        <Container maxWidth="md">
          <MonoName variant="h2">{'{access.CS()}'}</MonoName>
          <Typography variant="body1">
            AccessCS strives to provide underserved communities with a free
            education in computer science, that leads directly to employment
            opportunities in technology.
          </Typography>
          <p>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSubscribeClick}>
              Get Involved
            </Button>
          </p>
          <ArrowButton onClick={handleScrollClick}>
            <KeyboardArrowDown />
          </ArrowButton>
        </Container>
      </MainImage>
      <section ref={aboutRef}>
        <About />
      </section>
      <section ref={subscribeRef}>
        <Subscribe />
      </section>
    </Layout>
  )
}

export const FluidImage = graphql`
  fragment FluidImage on File {
    childImageSharp {
      fluid(maxWidth: 3000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const PageQuery = graphql`
  query {
    classroom: file(relativePath: { eq: "classroom.jpg" }) {
      ...FluidImage
    }
  }
`

export default IndexPage
