// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Typography } from '@material-ui/core'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
})

const Header = ({ siteTitle }) => {
  const classes = useStyles()

  const trigger = useScrollTrigger({ threshold: 600 })

  return (
    <Slide appear={false} direction="down" in={trigger}>
      <div className={classes.root}>
        <AppBar style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Toolbar>
            <Typography variant="h6">{'{access.CS()}'}</Typography>
          </Toolbar>
        </AppBar>
      </div>
    </Slide>
  )
}

export default Header
