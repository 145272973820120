// @flow
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import { theme } from '../styles/theme'
import Header from './header'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      {children}
    </ThemeProvider>
  )
}

export default Layout
